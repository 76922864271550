<template>
  <section class="presenters">
    <div class="presenters__content container">
      <h1 class="presenters__title">
        2025 Presenting Companies
      </h1>
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search by company name"
        class="presenters__search"
      />
      <!--<a href="/agenda"
        target="_blank" class="agenda-btn">
          Agenda
        </a>-->
      <div v-if="filteredLogos.length > 0" class="presenters__cards">
        <div
          class="presenters__item"
          v-for="(logo, index) in filteredLogos"
          :key="index"
          :class="logo.class"
        >
          <div class="presenters__item--logo">
            <img :src="logo.url" :alt="logo.name">
          </div>
          <div class="presenters__item--info">
            <div class="titles">
              <h2>{{ logo.name }}</h2>
              <p>{{ logo.market }}<template v-if="logo.ticker">:</template> {{ logo.ticker }} </p>
            </div>
            <a v-if="logo.path" :href="logo.path" target="_blank">View Company</a>
          </div>
        </div>
      </div>
      <p v-else class="no-matches">No matches found for your search.</p>
    </div>
  </section>
  <sponsors />
</template>

<style lang="scss" scoped>
  @import '@/styles/components/presenters.scss';
</style>

<script>
import sponsors from '@/components/sponsors.vue';

export default {
  name: 'presenters',
  components: {
    sponsors
  },
  data() {
    return {
      searchQuery: '',
      logos: [
        { url: require('../assets/presenters/tcbio.png'),
          name: 'TC BioPharm', class: 'tcbiopharm',
          market: 'NASDAQ', ticker: 'TCBP',
          path: 'https://tcbiopharm.com/'
        },
        { url: require('../assets/presenters/soligenix.png'),
          name: 'Soligenix', class: 'soligenix',
          market: 'NASDAQ', ticker: 'SNGX',
          path: 'https://www.soligenix.com/'
        },
        { url: require('../assets/presenters/lisata.png'),
          name: 'Lisata Therapeutics', class: 'lisata',
          market: 'NASDAQ', ticker: 'LSTA',
          path: 'https://www.lisata.com/'
        },
        { url: require('../assets/presenters/onar.svg'),
          name: 'ONAR', class: 'onar',
          market: 'OTCQB', ticker: 'RELT',
          path: 'https://www.onar.com/'
        },
        { url: require('../assets/presenters/lightpath.svg'),
          name: 'Lightpath', class: 'lightpath',
          market: 'NASDAQ', ticker: 'LPTH',
          path: 'https://www.lightpath.com/'
        },
        { url: require('../assets/presenters/pantheon.svg'),
          name: 'Pantheon Resources', class: 'pantheon',
          market: 'LON', ticker: 'PANR',
          path: 'https://www.pantheonresources.com/'
        },
        { url: require('../assets/presenters/alzamend.svg'),
          name: 'Alzamend Neuro', class: 'alzamend',
          market: 'NASDAQ', ticker: 'ALZN',
          path: 'https://alzamend.com/'
        },
        { url: require('../assets/presenters/geodrill.png'),
          name: 'Geodrill', class: 'geodrill',
          market: 'TSX', ticker: 'GEO',
          path: 'https://www.geodrill.ltd/'
        },
        { url: require('../assets/presenters/eckard.svg'),
          name: 'Eckard Enterprises', class: 'eckard',
          market: 'Private',
          path: 'https://eckardenterprises.com/'
        },
        { url: require('../assets/presenters/ipa.png'),
          name: 'ImmunoPrecise Antibodies', class: 'ipa',
          market: 'NASDAQ', ticker: 'IPA',
          path: 'https://www.ipatherapeutics.com/'
        },
        { url: require('../assets/presenters/ophc.png'),
          name: 'Optimum Bank', class: 'ophc',
          market: 'NASDAQ', ticker: 'OPHC',
          path: 'https://www.optimumbank.com/'
        },
        { url: require('../assets/presenters/strawberry.png'),
          name: 'Strawberry Fields REIT', class: 'strawberry',
          market: 'NYSE', ticker: 'STRW',
          path: 'https://www.strawberryfieldsreit.com/'
        },
        { url: require('../assets/presenters/hoth.svg'),
          name: 'Hoth Therapeutics', class: 'hoth',
          market: 'NASDAQ', ticker: 'HOTH',
          path: 'https://hoththerapeutics.com/'
        },
        { url: require('../assets/presenters/lifevantage.svg'),
          name: 'LifeVantage', class: 'lifevantage',
          market: 'NASDAQ', ticker: 'LFVN',
          path: 'https://ka1.lifevantage.com/us-en/?gad_source=1&gclid=CjwKCAiApsm7BhBZEiwAvIu2X0qUosLZ1RdWnh96lOe2k5JLoUzYqRADR27tYtoIqtz_tp_mABBjyRoC_W4QAvD_BwE'
        },
        { url: require('../assets/presenters/hydrograph.png'),
          name: 'HydroGraph Clean Power', class: 'hydrograph',
          market: 'CSE', ticker: 'HG | OTCQB: HGRAF',
          path: 'https://hydrograph.com/'
        },
        { url: require('../assets/presenters/ederra.png'),
          name: 'Ederra', class: 'ederra',
          market: 'Private', ticker: '',
          path: 'https://ederralyfe.com/'
        },
        { url: require('../assets/presenters/revitalist-lab.png'),
          name: 'Revitalist Lab', class: 'revitalist',
          market: 'Private', ticker: '',
          path: ''
        },
        { url: require('../assets/presenters/opendroids.svg'),
          name: 'Open Droids', class: 'opendroids',
          market: 'Private', ticker: '',
          path: 'https://opendroids.com/'
        },
        { url: require('../assets/presenters/jetai.png'),
          name: 'Jet.AI', class: 'jetai',
          market: 'NASDAQ', ticker: 'JTAI',
          path: 'https://jet.ai/'
        },
        { url: require('../assets/presenters/beam.png'),
          name: 'Beam Global', class: 'beam',
          market: 'NASDAQ', ticker: 'BEEM',
          path: 'https://beamforall.com/'
        },
        { url: require('../assets/presenters/wisa.png'),
          name: 'WiSA Technologies', class: 'wisa',
          market: 'NASDAQ', ticker: 'WISA',
          path: 'https://www.wisatechnologies.com/'
        },
        { url: require('../assets/presenters/ben.png'),
          name: 'Beneficient', class: 'beneficient',
          market: 'NASDAQ', ticker: 'BENFW',
          path: 'https://www.trustben.com/'
        },
        { url: require('../assets/presenters/defi.svg'),
          name: 'DeFi Technologies', class: 'defi',
          market: 'OTCMKTS', ticker: 'DEFTF',
          path: 'https://defi.tech/'
        },
        { url: require('../assets/presenters/gpo.png'),
          name: 'GPO Plus', class: 'gpo',
          market: 'OTCMKTS', ticker: 'GPOX',
          path: 'https://gpoplus.com/'
        },
        { url: require('../assets/presenters/gaxos.png'),
          name: 'Gaxos.AI', class: 'gaxos',
          market: 'NASDAQ', ticker: 'GXAI',
          path: 'https://gaxos.ai/'
        },
        { url: require('../assets/presenters/autoimm.png'),
          name: 'Autoimmunity BioSolutions', class: 'abs',
          market: 'Private', ticker: '',
          path: 'https://www.abstherapeutics.com/'
        },
        { url: require('../assets/presenters/quai.png'),
          name: 'Quai Network', class: 'quai',
          market: 'Crypto Token', ticker: '',
          path: 'https://qu.ai/'
        },
        { url: require('../assets/presenters/libera.svg'),
          name: 'Libera', class: 'libera',
          market: 'Crypto Token', ticker: '',
          path: 'https://www.liberaglobal.ai/'
        },
        { url: require('../assets/presenters/chintai.png'),
          name: 'Chintai', class: 'chintai',
          market: 'Crypto Token', ticker: '',
          path: 'https://chintai.io/'
        },
        { url: require('../assets/presenters/tidus.svg'),
          name: 'Tidus', class: 'tidus',
          market: 'Crypto Token', ticker: '',
          path: 'https://tiduswallet.com/'
        },
        { url: require('../assets/presenters/kredete.png'),
          name: 'Kredete', class: 'kredete',
          market: 'Crypto Token', ticker: '',
          path: 'https://www.kredete.com/'
        },
        { url: require('../assets/presenters/matterfi.svg'),
          name: 'MatterFi', class: 'matterfi',
          market: 'Crypto Token', ticker: '',
          path: 'https://www.matterfi.com/'
        },
        { url: require('../assets/presenters/zkalyx.png'),
          name: 'ZKALYX ', class: 'zkalyx',
          market: 'Crypto Token', ticker: '',
          path: 'https://www.zkalyx.com/'
        },
        { url: require('../assets/presenters/cbt.png'),
          name: 'CB Therapeutics ', class: 'cbt',
          market: 'Private', ticker: '',
          path: 'https://www.cbthera.com/'
        },
        { url: require('../assets/presenters/enthea.svg'),
          name: 'Enthea ', class: 'enthea',
          market: 'Private', ticker: '',
          path: 'https://www.enthea.com/'
        },
        { url: require('../assets/presenters/edibleg.png'),
          name: 'Edible Garden', class: 'edibleg',
          market: 'NASDAQ', ticker: 'EDBL',
          path: 'https://ediblegardenag.com/'
        },
        { url: require('../assets/presenters/wrap.png'),
          name: 'Wrap Technologies', class: 'wrap',
          market: 'NASDAQ', ticker: 'WRAP',
          path: 'https://wrap.com/'
        },
        { url: require('../assets/presenters/m2i.png'),
          name: 'M2i Global', class: 'm2i',
          market: 'OTCQB', ticker: 'MTWO',
          path: 'https://www.m2i.global/'
        },
        { url: require('../assets/presenters/raiinmaker.png'),
          name: 'Raiinmaker', class: 'raiinmaker',
          market: 'Private', ticker: '',
          path: 'https://www.raiinmaker.com/'
        },
        { url: require('../assets/presenters/qd5.png'),
          name: 'Quantum Defen5e', class: 'qd5',
          market: 'Private', ticker: '',
          path: 'https://qd5.com/en-us/'
        },
        { url: require('../assets/presenters/milo.png'),
          name: 'Milo', class: 'milo',
          market: 'Private', ticker: '',
          path: 'https://www.milo.io/'
        },
        { url: require('../assets/presenters/soar-2.png'),
          name: 'Soar AI Studio', class: 'soar',
          market: 'Private', ticker: '',
          path: 'https://try.soar.com/'
        },
        { url: require('../assets/presenters/artesano.png'),
          name: 'Artesano', class: 'artesano',
          market: 'Private', ticker: '',
          path: 'https://www.ronartesano.com/'
        },
        { url: require('../assets/presenters/puertogreen.png'),
          name: 'Puerto Green', class: 'puertogreen',
          market: 'Private', ticker: '',
          path: 'https://puerto.green/'
        },
        { url: require('../assets/presenters/skootel.png'),
          name: 'Skootel', class: 'skootel',
          market: 'Private', ticker: '',
          path: 'https://www.skootel.com/'
        },
        { url: require('../assets/presenters/cyberlandr.svg'),
          name: 'CyberLandr', class: 'cyberlandr',
          market: 'Private', ticker: '',
          path: 'https://www.cyberlandr.com/'
        },
        { url: require('../assets/presenters/zena.png'),
          name: 'ZenaTech', class: 'zenatech',
          market: 'NASDAQ', ticker: 'ZENA',
          path: 'https://www.zenatech.com/'
        },
        { url: require('../assets/presenters/hemostemix.png'),
          name: 'Hemostemix', class: 'hemostemix',
          market: 'CVE', ticker: 'HEM',
          path: 'https://hemostemix.com/'
        },
        { url: require('../assets/presenters/nuvini.png'),
          name: 'Nuvini', class: 'nuvini',
          market: 'NASDAQ', ticker: 'NVNI',
          path: 'https://www.nuvini.co/'
        },
        { url: require('../assets/presenters/bitech.png'),
          name: 'BiTech', class: 'bitech',
          market: 'OTC', ticker: 'BTTC',
          path: 'https://www.bitech.tech/'
        },
        { url: require('../assets/presenters/faraday.png'),
          name: 'Faraday', class: 'faraday',
          market: 'NASDAQ', ticker: 'FFIE',
          path: 'https://www.ff.com/'
        },
        { url: require('../assets/presenters/cibus.png'),
          name: 'Cibus', class: 'cibus',
          market: 'NASDAQ', ticker: 'CBUS',
          path: 'https://www.cibus.com/'
        },
        { url: require('../assets/presenters/brazilpotash.png'),
          name: 'Brazil Potash', class: 'brazilpotash',
          market: 'NYSE', ticker: 'GRO',
          path: 'https://brazilpotash.com/'
        },
        { url: require('../assets/presenters/pentagon.png'),
          name: 'Pentagon Games', class: 'pentagon',
          market: 'Private', ticker: '',
          path: 'https://pentagon.games/'
        }
      ],
    };
  },
  computed: {
    filteredLogos() {
      const query = this.searchQuery.toLowerCase();
      return this.logos.filter((logo) =>
        logo.name.toLowerCase().includes(query) || 
        (logo.ticker && logo.ticker.toLowerCase().includes(query))
      );
    },
  },
};
</script>