<template>
  <section class="sponsors">
    <div class="sponsors__content">
      <h1 class="sponsors__title">
          Title Sponsors
      </h1>
      <div class="sponsors__logos">
        <a
          v-for="sponsor in titleSponsors"
          :key="sponsor.name"
          :href="sponsor.url"
          target="_blank"
        >
          <div class="sponsors__item single">
            <div
              class="sponsors__logo-container"
              :class="sponsor.specialClass"
            >
              <img :src="getImageUrl(sponsor.logo)" :alt="sponsor.name" :class="sponsor.imgClass" />
            </div>
            <h2 class="sponsors__name">{{ sponsor.name }}</h2>
          </div>
        </a>
      </div>
      <h1 class="sponsors__title reg">
         2025 Sponsors
      </h1>
      <div class="sponsors__logos">
        <a
          v-for="sponsor in sponsors"
          :key="sponsor.name"
          :href="sponsor.url"
          target="_blank"
        >
          <div class="sponsors__item single">
            <div
              class="sponsors__logo-container"
              :class="sponsor.specialClass"
            >
              <img :src="getImageUrl(sponsor.logo)" :alt="sponsor.name" :class="sponsor.imgClass" />
            </div>
            <h2 class="sponsors__name">{{ sponsor.name }}</h2>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import sponsors from './json/sponsors.json';

export default {
  name: 'Sponsors',
  data() {
    return {
      titleSponsors: sponsors.titleSponsors,
      sponsors: sponsors.sponsors,
    };
  },
  methods: {
    getImageUrl(imageName) {
      return require(`../assets/sponsors/${imageName}`);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/styles/components/sponsors.scss';
</style>
