<template>
  <div class="lunch">
    <h2 class="subtitle">
      Lunch
    </h2>
    <div class="cards__container">
      <div class="cards__single grayblue">
        <p class="cards__title">
          Lunch <br> @ Patio del Fauno Lobby
        </p>
        <div class="cards__description">
          <p>12:00 PM - 1:00 PM</p>
        </div>
      </div>
    </div>
    <div class="cards__container">
      <div v-for="(speaker, index) in speakers" :key="index" class="cards__single cerulean" :class="speaker.class">
        <div v-if="speaker.imgClass" :class="'speaker-img ' + speaker.imgClass"></div>
        <div v-if="speaker.imgClass" class="circle"></div>
        <div class="cards__speaker">
          <p class="cards__name">{{ speaker.name }}</p>
          <p class="cards__spktitle" v-html="speaker.title"></p>
          <p class="cards__spktitle" v-if="speaker.position" v-html="speaker.position"></p>
        </div>
        <div class="cards__description">
          <p class="time">{{ speaker.time }}</p>
          <p class="location">{{ speaker.location }}</p>
        </div>
      </div>
    </div>
  </div>
  <div :class="{'modal-show': isModalOpen}" v-if="isModalOpen" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <h1 class="speaker">{{ activeCardInfo?.speaker }}</h1>
      <h2 class="title">{{ activeCardInfo?.title }}</h2>
      <p class="description" v-html="activeCardInfo?.description"></p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Day Three Lunch',
  data() {
    return {
      eventDay: 3,
      speakers: [
        {
          imgClass: 'swider',
          name: 'Eric Swider',
          title: '“Hearing through the noise to take advantage of inefficient market opportunities”',
          position: 'Truth Social Board Member',
          time: '12:30 PM - 1:00 PM',
          location: 'Patio del Fauno',
          class: 'moreheight'
        },
        {
          name: 'Panel: Psychedelic investing What A Long Strange Trip It\'s Been',
          title: 'Panelists:<br>- Kathryn Walker, CEO & Founder - Revitalist Lab<br>- Sher Ali Butt, Founder & CEO - CB Therapeutics<br>- Sherry Rais, CEO & Co-Founder - Enthea<br>- Lizzy Sands, Chief of Staff - Freedom Biosciences',
          time: '1:00 PM - 2:00 PM',
          location: 'Patio del Fauno',
          class: 'noimg noheight'
        }
      ],
      cardData: {
      },
      activeCardInfo: null,
      isModalOpen: false
    }
  },
  methods: {
    openModal(cardId) {
      this.activeCardInfo = this.cardData[cardId];
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.activeCardInfo = null;
    }
  },
  computed: {
    current() {
      return new Date();
    }
  },
};
</script>