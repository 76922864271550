<template>
    <div class="afternoon">
    <h2 class="subtitle">
      Afternoon Sessions
    </h2>
    <div v-if="isMobile" class="mobile slider">
      <carousel>
        <slide :key="0">
          <div class="grid__tracks">
            <div class="--title">
              Patio del Fauno
            </div>
            <div class="--slot t1" v-for="info in fauno" :key="info.company" @click="openModal(info.company)">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="1">
          <div class="grid__tracks">
            <div class="--title">
              Track 1 (Fauno West)
            </div>
            <div class="--slot t1" v-for="info in track1" :key="info.company" @click="openModal(info.company)">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="2">
          <div class="grid__tracks">
            <div class="--title">
              Track 2 (Fauno East)
            </div>
            <div class="--slot t2" v-for="info in track2" :key="info.company" @click="openModal(info.company)">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div v-else>
      <div class="grid__container">
        <div class="grid__tracks">
          <div class="--title">
            Patio del Fauno
          </div>
          <div class="--slot t0 clickable" v-for="info in fauno" :key="info.company" @click="openModal(info.company)">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 1 (Fauno West)
          </div>
          <div class="--slot t1 clickable" :class="info.class" v-for="info in track1" :key="info.company" @click="openModal(info.company)">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 2 (Fauno East)
          </div>
          <div class="--slot t2 clickable" v-for="info in track2" :key="info.company" @click="openModal(info.company)">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cards__container">
    <div v-for="(speaker, index) in speakers" :key="index" class="cards__single cerulean" :class="speaker.class">
      <div v-if="speaker.imgClass" :class="'speaker-img ' + speaker.imgClass"></div>
      <div v-if="speaker.imgClass" class="circle"></div>
      <div class="cards__speaker">
        <p class="cards__name">{{ speaker.name }}</p>
        <p class="cards__spktitle" v-html="speaker.title"></p>
        <p class="cards__spktitle" v-html="speaker.position"></p>
      </div>
      <div class="cards__description">
        <p class="time">{{ speaker.time }}</p>
        <p class="location">{{ speaker.location }}</p>
      </div>
    </div>
    <div class="cards__single green">
      <p class="cards__title">
        Closing Cocktail Party
        <br>
        @ West Veranda, Condado Vanderbilt
      </p>
      <div class="cards__description">
        <p>5:00 PM - 8:00 PM</p>
      </div>
    </div>
  </div>
  <div :class="{ 'modal-show': isModalOpen }" v-if="isModalOpen" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <div v-if="errorMessage">
        <h1 class="title">Error</h1>
        <p class="description">{{ errorMessage }}</p>
      </div>
      <div v-else>
        <h1 class="speaker">{{ activeCardInfo?.speaker || activeCompanyInfo?.company }}</h1>
        <h2 v-if="activeCardInfo" class="title">{{ activeCardInfo.title }}</h2>
        <p class="description" v-if="activeCardInfo" v-html="activeCardInfo.description"></p>
        <p class="description" v-if="activeCompanyInfo" v-html="activeCompanyInfo.description"></p>
        <a
          v-if="activeCompanyInfo?.url"
          :href="activeCompanyInfo.url"
          target="_blank"
          class="modal-link"
        >
          Visit Website
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'Day Three Afternoon',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      fauno: [
        { start: '', end: '', company: '', hr: '14:00', duration: 29 },
        { start: '2:00 PM', end: '2:30 PM', company: 'Pentagon Games', hr: '14:00', duration: 29 },
        { start: '2:30 PM', end: '3:00 PM', company: 'Zkalyx', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '3:30 PM', company: 'Analog', hr: '15:00', duration: 29 },
        { start: '3:30 PM', end: '4:00 PM', company: 'Open Droids', hr: '15:30', duration: 29 }
      ],
      track1: [
        { start: '1:30 PM', end: '2:15 PM', company: 'Panel: Free Markets, Open Data, & Access', hr: '14:00', duration: 29 },
        { start: '2:15 PM', end: '2:30 PM', company: 'Moneyball', hr: '14:00', duration: 29 },
        { start: '2:30 PM', end: '3:00 PM', company: 'Wealth2B | Cuvro', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '3:30 PM', company: 'Mahoru Catamarans | Puerto Green', hr: '15:00', duration: 29 },
        { start: '3:30 PM', end: '4:00 PM', company: 'Artesano | Angel AI', hr: '15:30', duration: 29 },
        { start: '4:00 PM', end: '4:15 PM', company: 'Skootel', hr: '15:30', duration: 29 }
      ],
      track2: [
        { start: '', end: '', company: '', hr: '14:00', duration: 29 },
        { start: '2:00 PM', end: '2:30 PM', company: 'Optimum Bank', hr: '14:00', duration: 29 },
        { start: '2:30 PM', end: '3:00 PM', company: 'Enthea', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '3:30 PM', company: 'GPO Plus', hr: '15:00', duration: 29 },
        { start: '3:30 PM', end: '4:00 PM', company: 'Brazil Potash', hr: '15:30', duration: 29 }
      ],
      companyData: {
        'Optimum Bank': {
          description: 'Optimum Bank (OPHC) is a customer-focused financial institution committed to providing innovative banking services and personalized solutions. Dedicated to fostering growth for individuals and businesses, Optimum Bank combines modern technology with exceptional service to help clients achieve their financial aspirations.',
          url: 'https://www.optimumbank.com/'
        },
        'GPO Plus': {
          description: 'GPOPlus+ is a leading distributor specializing in direct-to-store (DSD) delivery of high-demand consumer products to convenience stores and gas stations. The company leverages innovative technology and strategic partnerships with retailers and manufacturers to optimize product selection and ensure consistent inventory, driving success in specialty retail.',
          url: 'https://gpoplus.com/'
        },
        'Panel: Free Markets, Open Data, & Access': {
          description: 'Moderator: Brian Bourgerie<br><br>Companies:<br><a href="https://soar.com/" target="_blank">Soar AI</a><br><a href="https://libertyventures.xyz/" target="_blank">Liberty Ventures</a><br><a href="https://www.sweaterventures.com/" target="_blank">Sweater VC</a>'
        },
        'Moneyball': {
          url: 'https://www.moneyball.ai/'
        },
        'Wealth2B | Cuvro': {
          description: 'Website: <a href="https://wealth2b.com/" target="_blank">Wealth2B</a><br>Website: <a href="https://getcuvro.com/en/" target="_blank">Cuvro</a>'
        },
        'Mahoru Catamarans | Puerto Green': {
          description: 'Website: <a href="https://www.mahoru.com/" target="_blank">Mahoru Catamarans</a><br>Website: <a href="https://puerto.green/" target="_blank">Puerto Green</a>'
        },
        'Artesano | Angel AI': {
          description: 'Website: <a href="https://www.ronartesano.com/" target="_blank">Artesano</a><br>Website: <a href="https://www.angelai.com/" target="_blank">Angel AI</a>'
        },
        'Skootel': {
          url: 'https://www.skootel.com/'
        },
      },
      speakers: [
        {
          imgClass: 'divine',
          name: 'John Divine',
          title: '"Bitcoin Options Markets: Exploring Risk Management & Yield Strategies"',
          position: 'BlockFills, Digital Asset OTC Trading',
          time: '4:00 PM - 4:30 PM',
          location: 'Patio del Fauno'
        },
        {
          imgClass: 'pierce',
          name: 'Brock Pierce',
          position: 'Co-Founder - DNA',
          time: '4:30 PM - 5:00 PM',
          location: 'Patio del Fauno'
        },
      ],
      cardData: {
      },
      activeCardInfo: null,
      isModalOpen: false,
      errorMessage: null,
      isMobile: false,
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
    openModal(identifier) {
      if (this.cardData[identifier]) {
        this.activeCardInfo = this.cardData[identifier];
        this.activeCompanyInfo = null;
        this.errorMessage = null;
        this.isModalOpen = true;
      } else if (this.companyData[identifier]) {
        this.activeCompanyInfo = {
          ...this.companyData[identifier],
          company: identifier,
        };
        this.activeCardInfo = null;
        this.errorMessage = null;
        this.isModalOpen = true;
      } else {
        console.error(`Currently no info has been found for "${identifier}".`);
        this.activeCardInfo = null;
        this.activeCompanyInfo = null;
        this.errorMessage = null;
        this.isModalOpen = false;
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.activeCardInfo = null;
      this.activeCompanyInfo = null;
      this.errorMessage = null;
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};

</script>