<template>
  <div class="cards__container">
    <div class="cards__single gray">
      <img src="@/assets/agenda/icons/registration.png" class="cards__icon cards__mobile" alt="Registration Icon">
      <p class="cards__title cards__desktop">
        Registration <br> @ Patio del Fauno Lobby
      </p>
      <div class="cards__description">
        <h3 class="cards__title cards__mobile">
          Registration <br> @ Patio del Fauno Lobby
        </h3>
        <p>7:00 AM - 4:00 PM</p>
        <img src="@/assets/agenda/icons/registration.png" class="cards__icon cards__desktop" alt="Registration Icon">
      </div>
    </div>
    <div class="cards__single grayblue">
      <p class="cards__title">
        Breakfast <br> @ Patio del Fauno Lobby
      </p>
      <div class="cards__description">
        <p>8:00 AM - 10:00 AM</p>
      </div>
    </div>
    <div v-for="(speaker, index) in speakers" :key="index" class="cards__single cerulean" :class="speaker.class">
      <div v-if="speaker.imgClass" :class="'speaker-img ' + speaker.imgClass"></div>
      <div v-if="speaker.imgClass" class="circle"></div>
      <div class="cards__speaker">
        <p class="cards__name">{{ speaker.name }}</p>
        <p class="cards__spktitle" v-html="speaker.title"></p>
        <p class="cards__spktitle" v-html="speaker.position"></p>
      </div>
      <div class="cards__description">
        <p class="time">{{ speaker.time }}</p>
        <p class="location">{{ speaker.location }}</p>
      </div>
    </div>
    <div class="cards__single purple">
      <img src="@/assets/agenda/icons/meets.png" class="cards__icon cards__mobile" alt="Meetings Icon">
      <p class="cards__title cards__desktop">
        1:1 Meetings
        <br>@ Salon Dorado
      </p>
      <div class="cards__description">
        <h3 class="cards__title cards__mobile">
          1:1 Meetings <br>@ Salon Dorado
        </h3>
        <p>10:00 AM - 4:00 PM</p>
        <img src="@/assets/agenda/icons/meets.png" class="cards__icon cards__desktop" alt="Meetings Icon">
      </div>
    </div>
  </div>
  <div class="morning">
    <h2 class="subtitle">
      Morning Sessions
    </h2>
    <div v-if="isMobile" class="mobile slider">
      <carousel>
        <slide :key="1">
          <div class="grid__tracks">
            <div class="--title">
              Track 1 (Fauno West)
            </div>
            <div class="--slot t1" v-for="info in track1" :key="info.company" @click="openModal(info.company)">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="2">
          <div class="grid__tracks">
            <div class="--title">
              Track 2 (Fauno East)
            </div>
            <div class="--slot t2" v-for="info in track2" :key="info.company" @click="openModal(info.company)">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="3">
          <div class="grid__tracks">
            <div class="--title">
              Track 3 (Salon Azul)
            </div>
            <div class="--slot t3" v-for="info in track3" :key="info.company" @click="openModal(info.company)">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div v-else>
      <div class="grid__container">
        <div class="grid__tracks">
          <div class="--title">
            Track 1 (Fauno West)
          </div>
          <div class="--slot t1 clickable" v-for="info in track1" :key="info.company" @click="openModal(info.company)">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 2 (Fauno East)
          </div>
          <div class="--slot t2 clickable" v-for="info in track2" :key="info.company" @click="openModal(info.company)">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 3 (Salon Azul)
          </div>
          <div class="--slot t3 clickable" v-for="info in track3" :key="info.company" @click="openModal(info.company)">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cards__container">
    <div v-for="(presenter, index) in presenters" :key="index" class="cards__single grayblue presenter"
    @click="openModal(presenter.company)">
      <div class="cards__info">
        <p class="cards__title">
          {{ presenter.company}}
        </p>
        <div class="cards__description">
          <p class="time"> {{ presenter.time }}</p>
          <p>{{ presenter.location }}</p>
        </div>
      </div>
    </div>
  </div>
  <div :class="{ 'modal-show': isModalOpen }" v-if="isModalOpen" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <div v-if="errorMessage">
        <h1 class="title">Error</h1>
        <p class="description">{{ errorMessage }}</p>
      </div>
      <div v-else>
        <h1 class="speaker">{{ activeCardInfo?.speaker || activeCompanyInfo?.company }}</h1>
        <h2 v-if="activeCardInfo" class="title">{{ activeCardInfo.title }}</h2>
        <p class="description" v-if="activeCardInfo" v-html="activeCardInfo.description"></p>
        <p class="description" v-if="activeCompanyInfo">{{ activeCompanyInfo.description }}</p>
        <a
          v-if="activeCompanyInfo?.url"
          :href="activeCompanyInfo.url"
          target="_blank"
          class="modal-link"
        >
          Visit Website
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'Day Two Morning',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      track1: [
        { start: '10:00 AM', end: '10:30 AM', company: 'Lisata Therapeutics', hr: '10:00', duration: 29 },
        { start: '10:30 AM', end: '11:00 AM', company: 'TC Biopharm', hr: '10:30', duration: 29 },
        { start: '11:00 AM', end: '11:30 AM', company: 'ONAR', hr: '11:00', duration: 29 },
        { start: '11:30 AM', end: '12:00 PM', company: 'Pantheon Resources', hr: '11:30', duration: 29 }
      ],
      track2: [
        { start: '10:00 AM', end: '10:30 AM', company: 'Soligenix', hr: '10:00', duration: 29 },
        { start: '10:30 AM', end: '11:00 AM', company: 'HydroGraph', hr: '10:30', duration: 29 },
        { start: '11:00 AM', end: '11:30 AM', company: 'Lightpath', hr: '11:00', duration: 29 },
        { start: '11:30 AM', end: '12:00 PM', company: 'Alzamend Neuro', hr: '11:30', duration: 29 }
      ],
      track3: [
        { start: '10:00 AM', end: '10:30 AM', company: 'Geodrill', hr: '10:00', duration: 29 },
        { start: '10:30 AM', end: '11:00 AM', company: 'ImmunoPrecise Antibodies', hr: '10:30', duration: 29 },
        { start: '11:00 AM', end: '11:30 AM', company: 'Eckard Enterprises', hr: '11:00', duration: 29 },
        { start: '11:30 AM', end: '12:00 PM', company: 'Hoth Therapeutics', hr: '11:30', duration: 29 }
      ],
      companyData: {
        'Lisata Therapeutics': {
          description: 'Lisata Therapeutics, Inc. is a clinical-stage biopharmaceutical company dedicated to developing innovative therapies for advanced cardiovascular and cancer conditions. With a focus on addressing unmet medical needs, their pipeline includes novel treatments designed to enhance the body\'s natural healing mechanisms and improve patient outcomes. Lisata Therapeutics combines scientific expertise with a commitment to transforming healthcare in challenging disease areas.',
          url: 'https://www.lisata.com/',
        },
        'TC Biopharm': {
          description: 'TC Biopharm Limited is a biotechnology company developing cutting-edge cell therapies for cancer and infectious diseases. By leveraging gamma-delta T cell technology, they create off-the-shelf treatments that harness the immune system to target harmful cells. Focused on improving patient outcomes, TC Biopharm is at the forefront of advancing accessible, next-generation immunotherapies.',
          url: 'https://tcbiopharm.com/',
        },
        'ONAR': {
          description: 'The company was founded in 2021 with a vision to acquire and operate technology-first marketing agencies that deliver measurable results for growing companies, creating an unmatched network to help middle-market companies expand their stories through groundbreaking technology. Powered by automation, human ingenuity, and a dedicated team of designers, data scientists, healthcare experts, and strategists, it crafts the best strategies for its clients. By harnessing real-time data, AI, and next-gen technologies, the company transforms brands while preserving the humanity that makes each campaign resonate.',
          url: 'https://www.onar.com/'
        },
        'Pantheon Resources': {
          description: 'Pantheon Resources plc is an energy exploration and production company focused on discovering and developing oil and gas resources primarily in Alaska. Committed to sustainable operations, Pantheon utilizes advanced technology to maximize energy production while minimizing environmental impact. With significant onshore projects, the company plays a crucial role in supporting energy needs through responsible resource development.',
          url: 'https://www.pantheonresources.com/'
        },
        'Soligenix': {
          description: 'Soligenix, Inc. is a biopharmaceutical company focused on developing treatments for rare diseases and unmet medical needs in oncology, biodefense, and inflammation. Their innovative therapies target areas such as pediatric Crohn\'s disease and gastrointestinal acute radiation syndrome, aiming to improve patient outcomes in challenging conditions. Committed to advancing healthcare, Soligenix leverages cutting-edge research to deliver impactful solutions for complex medical challenges.',
          url: 'https://www.soligenix.com/'
        },
        'HydroGraph': {
          description: 'HydroGraph Clean Power Inc. engages in the acquisition and development of graphene and hydrogen related products and services. It offers patented technology to produce graphene, hydrogen, syngas, and other products. The company was formerly known as Carbon-2D Graphene Inc and changed its name to HydroGraph Clean Power Inc. in March 2021. HydroGraph Clean Power Inc. was incorporated in 2017 and is based in Toronto, Canada.',
          url: 'https://hydrograph.com/'
        },
        'Lightpath': {
          description: 'Lightpath is a telecommunications company specializing in high-speed, fiber-optic network solutions for businesses and enterprises. With a focus on delivering reliable, high-capacity connectivity, Lightpath serves industries across healthcare, finance, education, and media. Their advanced infrastructure and customer-centric approach empower clients to meet growing data demands while enabling digital transformation and seamless communication.',
          url: 'https://www.lightpath.com/'
        },
        'Alzamend Neuro': {
          description: 'Alzamend Neuro is a biotechnology company focused on developing innovative treatments for Alzheimer’s disease and other neurodegenerative disorders. Driven by a commitment to improving quality of life, Alzamend leverages cutting-edge research to address unmet medical needs in brain health.',
          url: 'https://alzamend.com/'
        },
        'Geodrill': {
          description: 'Geodrill is a leading exploration drilling company providing high-quality drilling services to the mining industry across Africa and South America. With a strong commitment to safety, efficiency, and sustainability, Geodrill supports resource companies in uncovering valuable mineral deposits.',
          url: 'https://www.geodrill.ltd/'
        },
        'ImmunoPrecise Antibodies': {
          description: 'ImmunoPrecise Antibodies is a full-service contract research organization specializing in custom antibody discovery and development. With innovative technologies and a global network of laboratories, they deliver tailored solutions to accelerate research and therapeutic advancements in the life sciences industry.',
          url: 'https://www.ipatherapeutics.com/'
        },
        'Eckard Enterprises': {
          description: 'Eckard Enterprises, LLC specializes in strategic investments in energy, real estate, and technology, focusing on creating long-term value for its stakeholders. By leveraging decades of expertise and a commitment to innovation, the company delivers sustainable growth opportunities for its partners and clients.',
          url: 'https://eckardenterprises.com/'
        },
        'Hoth Therapeutics': {
          description: 'Hoth Therapeutics is a clinical-stage biopharmaceutical company dedicated to developing innovative, impactful, and ground-breaking treatments with a goal to improve patient quality of life.',
          url: 'https://hoththerapeutics.com/'
        },
        '': {
          description: '',
          url: ''
        },
      },
      speakers: [
        {
          imgClass: 'miglino',
          name: 'Chris Miglino',
          title: '"Welcome Keynote"',
          position: 'CEO - DNA',
          time: '9:00 AM - 9:30 AM',
          location: 'Patio del Fauno'
        },
        {
          imgClass: 'terpin',
          name: 'Michael Terpin',
          title: '"Bitcoin Super Cycle"',
          position: 'Founder and CEO - Transform Group, BitAngels',
          time: '9:30 AM - 10:00 AM',
          location: 'Patio del Fauno'
        },
      ],
      presenters: [
        {
          company: 'Matterfi',
          time: '11:30 AM - 12:00 PM',
          location: 'Patio del Fauno'
        },
      ],
      cardData: {
      },
      activeCardInfo: null,
      errorMessage: null,
      isModalOpen: false,
      isMobile: false,
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
    openModal(identifier) {
      if (this.cardData[identifier]) {
        this.activeCardInfo = this.cardData[identifier];
        this.activeCompanyInfo = null;
        this.errorMessage = null;
        this.isModalOpen = true;
      } else if (this.companyData[identifier]) {
        this.activeCompanyInfo = {
          ...this.companyData[identifier],
          company: identifier,
        };
        this.activeCardInfo = null;
        this.errorMessage = null;
        this.isModalOpen = true;
      } else {
        console.error(`Currently no info has been found for "${identifier}".`);
        this.activeCardInfo = null;
        this.activeCompanyInfo = null;
        this.errorMessage = null;
        this.isModalOpen = false;
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.activeCardInfo = null;
      this.activeCompanyInfo = null;
      this.errorMessage = null;
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
