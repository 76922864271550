<template>
  <div class="cards__container">
    <div class="cards__single gray">
      <img src="@/assets/agenda/icons/registration.png" class="cards__icon cards__mobile" alt="Registration Icon">
      <p class="cards__title cards__desktop">
        Registration <br> @ Patio del Fauno Lobby
      </p>
      <div class="cards__description">
        <h3 class="cards__title cards__mobile">
          Registration <br> @ Patio del Fauno Lobby
        </h3>
        <p>7:00 AM - 4:00 PM</p>
        <img src="@/assets/agenda/icons/registration.png" class="cards__icon cards__desktop" alt="Registration Icon">
      </div>
    </div>
    <div class="cards__single grayblue">
      <p class="cards__title">
        Breakfast <br> @ Patio del Fauno Lobby
      </p>
      <div class="cards__description">
        <p>8:00 AM - 10:00 AM</p>
      </div>
    </div>
    <div v-for="(speaker, index) in speakers" :key="index" class="cards__single cerulean" :class="speaker.class">
      <div v-if="speaker.imgClass" :class="'speaker-img ' + speaker.imgClass"></div>
      <div v-if="speaker.imgClass" class="circle"></div>
      <div class="cards__speaker">
        <p class="cards__name">{{ speaker.name }}</p>
        <p class="cards__spktitle" v-html="speaker.title"></p>
        <p class="cards__spktitle" v-html="speaker.position"></p>
      </div>
      <div class="cards__description">
        <p class="time">{{ speaker.time }}</p>
        <p class="location">{{ speaker.location }}</p>
      </div>
    </div>
    <div class="cards__single purple">
      <img src="@/assets/agenda/icons/meets.png" class="cards__icon cards__mobile" alt="Meetings Icon">
      <p class="cards__title cards__desktop">
        1:1 Meetings 
        <br>@ Salon Dorado
      </p>
      <div class="cards__description">
        <h3 class="cards__title cards__mobile">
          1:1 Meetings <br>@ Salon Dorado
        </h3>
        <p>10:00 AM - 4:00 PM</p>
        <img src="@/assets/agenda/icons/meets.png" class="cards__icon cards__desktop" alt="Meetings Icon">
      </div>
    </div>
  </div>
  <div class="morning">
    <h2 class="subtitle">
      Morning Sessions
    </h2>
    <div v-if="isMobile" class="mobile slider">
      <carousel>
        <slide :key="1">
          <div class="grid__tracks">
            <div class="--title">
              Track 1 (Fauno West)
            </div>
            <div class="--slot t1" v-for="info in track1" :key="info.company" @click="openModal(info.company)">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="2">
          <div class="grid__tracks">
            <div class="--title">
              Track 2 (Fauno East)
            </div>
            <div class="--slot t2" v-for="info in track2" :key="info.company" @click="openModal(info.company)">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="3">
          <div class="grid__tracks">
            <div class="--title">
              Track 3 (Salon Azul)
            </div>
            <div class="--slot t3" v-for="info in track3" :key="info.company" @click="openModal(info.company)">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div v-else>
      <div class="grid__container">
        <div class="grid__tracks">
          <div class="--title">
            Track 1 (Fauno West)
          </div>
          <div class="--slot t1 clickable" v-for="info in track1" :key="info.company" @click="openModal(info.company)">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 2 (Fauno East)
          </div>
          <div class="--slot t2 clickable" v-for="info in track2" :key="info.company" @click="openModal(info.company)">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 3 (Salon Azul)
          </div>
          <div class="--slot t3 clickable" v-for="info in track3" :key="info.company" @click="openModal(info.company)">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cards__container">
    <div v-for="(presenter, index) in presenters" :key="index" class="cards__single grayblue presenter"
    @click="openModal(presenter.company)">
      <div class="cards__info">
        <p class="cards__title">
          {{ presenter.company}}
        </p>
        <div class="cards__description">
          <p class="time"> {{ presenter.time }}</p>
          <p>{{ presenter.location }}</p>
        </div>
      </div>
    </div>
  </div>
  <div :class="{ 'modal-show': isModalOpen }" v-if="isModalOpen" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <div v-if="errorMessage">
        <h1 class="title">Error</h1>
        <p class="description">{{ errorMessage }}</p>
      </div>
      <div v-else>
        <h1 class="speaker">{{ activeCardInfo?.speaker || activeCompanyInfo?.company }}</h1>
        <h2 v-if="activeCardInfo" class="title">{{ activeCardInfo.title }}</h2>
        <p class="description" v-if="activeCardInfo" v-html="activeCardInfo.description"></p>
        <p class="description" v-if="activeCompanyInfo">{{ activeCompanyInfo.description }}</p>
        <a
          v-if="activeCompanyInfo?.url"
          :href="activeCompanyInfo.url"
          target="_blank"
          class="modal-link"
        >
          Visit Website
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'Day Three Morning',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      track1: [
        { start: '10:00 AM', end: '10:30 AM', company: 'Raiinmaker', hr: '10:00', duration: 29 },
        { start: '10:30 AM', end: '11:00 AM', company: 'CyberLandr', hr: '10:30', duration: 29 },
        { start: '11:00 AM', end: '11:30 AM', company: 'Hemostemix', hr: '11:00', duration: 29 },
        { start: '11:30 AM', end: '12:00 PM', company: 'Zena Tech', hr: '11:30', duration: 29 }
      ],
      track2: [
        { start: '10:00 AM', end: '10:30 AM', company: 'Revitalist Lab', hr: '10:00', duration: 29 },
        { start: '10:30 AM', end: '11:00 AM', company: 'CB Therapeutics', hr: '10:30', duration: 29 },
        { start: '11:00 AM', end: '11:30 AM', company: 'Strawberry Fields', hr: '11:00', duration: 29 },
        { start: '11:30 AM', end: '12:00 PM', company: 'Ederra', hr: '11:30', duration: 29 }
      ],
      track3: [
        { start: '10:00 AM', end: '10:30 AM', company: 'BiTech', hr: '10:00', duration: 29 },
        { start: '10:30 AM', end: '11:00 AM', company: 'Nuvini', hr: '10:30', duration: 29 },
        { start: '11:00 AM', end: '11:30 AM', company: 'Faraday', hr: '11:00', duration: 29 },
        { start: '11:30 AM', end: '12:00 PM', company: 'Freedom Biosciences', hr: '11:30', duration: 29 }
      ],
      companyData: {
        'CB Therapeutics': {
          description: 'CB Therapeutics produces high-value molecules, compounds, and rare ingredients from simple sugars utilizing yeast and the process of fermentation. Since its inception, CB Therapeutics has developed standardized biotechnology processes for producing natural molecules and their analogs for clinical research in psychedelic medicine, cannabinoid-based therapies, and food technologies. As a drug discovery platform with the ability to create a clean and secure supply of products, The Company\'s partnership allows it to develop custom production process technologies to support and scale drug product manufacturing to meet clinical patient supply demands. In turn, this enables partners to move more swiftly through clinical development.',
          url: 'https://www.cbthera.com/'
        },
        'Strawberry Fields': {
          description: 'Strawberry Fields REIT, Inc. operates under the ticker symbol STRW and is traded on the NYSE American market. The company specializes in owning and leasing skilled nursing and healthcare-related properties.',
          url: 'https://www.strawberryfieldsreit.com/'
        },
        'Ederra': {
          description: 'Ederra is a wellness company dedicated to empowering individuals to take control of their health through natural, nutrient-rich supplements. Their flagship product, EMPWR+, combines broccoli microgreens and Lion\'s Mane mushrooms to enhance cognitive function, provide antioxidant protection, and promote overall well-being.',
          url: 'https://ederralyfe.com/'
        }
      },
      speakers: [
        {
          imgClass: 'tom',
          name: 'Tom McLaughlin',
          position: 'CIO - DNA Fund',
          time: '9:00 AM - 9:30 AM',
          location: 'Patio del Fauno'
        },
        {
          imgClass: 'naeem',
          name: 'Naeem Mahmood',
          title: '“Peak Performance and Mindset for Investors”',
          position: 'Founder - Peak3',
          time: '9:30 AM - 10:00 AM',
          location: 'Patio del Fauno'
        },
      ],
      presenters: [
        {
          company: 'Libera',
          time: '11:30 AM - 12:00 PM',
          location: 'Patio del Fauno'
        },
        {
          company: 'Quai',
          time: '12:00 PM - 12:30 PM',
          location: 'Patio del Fauno'
        },
      ],
      cardData: {
      },
      activeCardInfo: null,
      isModalOpen: false,
      isMobile: false,
      errorMessage: null,
      eventDay: 3
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
    openModal(identifier) {
      if (this.cardData[identifier]) {
        this.activeCardInfo = this.cardData[identifier];
        this.activeCompanyInfo = null;
        this.errorMessage = null;
        this.isModalOpen = true;
      } else if (this.companyData[identifier]) {
        this.activeCompanyInfo = {
          ...this.companyData[identifier],
          company: identifier,
        };
        this.activeCardInfo = null;
        this.errorMessage = null;
        this.isModalOpen = true;
      } else {
        console.error(`Currently no info has been found for "${identifier}".`);
        this.activeCardInfo = null;
        this.activeCompanyInfo = null;
        this.errorMessage = null;
        this.isModalOpen = false;
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.activeCardInfo = null;
      this.activeCompanyInfo = null;
      this.errorMessage = null;
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
