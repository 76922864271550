<template>
  <section class="formcta">
    <div class="formcta__content container">
      <a href="https://forms.monday.com/forms/5ab96b8f289480d9d3479556bcc057e0?r=use1" target="_blank">
        <button class="formcta__btn presenter-btn">{{ buttonText }}</button>
      </a>
    </div>
  </section>
</template>

<style lang="scss" scoped type="text/css">
@import "@/styles/components/formcta.scss";
</style>

<script>

export default {
  name: "generalformcta",
  props: {
    buttonText: {
      type: String,
      default: 'Register for 2026'
    }
  },
  data() {
    return {
      generalModal: false,
    };
  },
  methods: {
    showModal() {
      this.generalModal = true
      document.body.classList.add('modal-open');
    },
    hideModal() {
      this.generalModal = false;
      document.body.classList.remove('modal-open');
    }
  }
};
</script>
