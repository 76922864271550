<template>
  <div class="afternoon">
    <h2 class="subtitle">
      Afternoon Sessions
    </h2>
    <div v-if="isMobile" class="mobile slider">
      <carousel>
        <slide :key="0">
          <div class="grid__tracks">
            <div class="--title">
              Patio del Fauno
            </div>
            <div class="--slot t1" v-for="info in fauno" :key="info.company" @click="openModal(info.company)">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="1">
          <div class="grid__tracks">
            <div class="--title">
              Track 1 (Fauno West)
            </div>
            <div class="--slot t1" v-for="info in track1" :key="info.company" @click="openModal(info.company)">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="2">
          <div class="grid__tracks">
            <div class="--title">
              Track 2 (Fauno East)
            </div>
            <div class="--slot t2" v-for="info in track2" :key="info.company" @click="openModal(info.company)">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <slide :key="3">
          <div class="grid__tracks">
            <div class="--title">
              Track 3 (Salon Azul)
            </div>
            <div class="--slot t3" v-for="info in track3" :key="info.company" @click="openModal(info.company)">
              <div class="--slot__time">
                <p>{{ info.start }}</p>
                <p>{{ info.end }}</p>
              </div>
              <p class="--slot__title">
                {{ info.company }}
              </p>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div v-else>
      <div class="grid__container four-column">
        <div class="grid__tracks">
          <div class="--title">
            Patio del Fauno
          </div>
          <div class="--slot t0 clickable" v-for="info in fauno" :key="info.company" @click="openModal(info.company)">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 1 (Fauno West)
          </div>
          <div class="--slot t1 clickable" v-for="info in track1" :key="info.company" @click="openModal(info.company)">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 2 (Fauno East)
          </div>
          <div class="--slot t2 clickable" v-for="info in track2" :key="info.company" @click="openModal(info.company)">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
        <div class="grid__tracks">
          <div class="--title">
            Track 3 (Salon Azul)
          </div>
          <div class="--slot t3 clickable" v-for="info in track3" :key="info.company" @click="openModal(info.company)">
            <div class="--slot__time">
              <p>{{ info.start }}</p>
              <p>{{ info.end }}</p>
            </div>
            <p class="--slot__title">
              {{ info.company }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cards__container">
    <div v-for="(speaker, index) in speakers" :key="index" class="cards__single cerulean" :class="speaker.class">
      <div v-if="speaker.imgClass" :class="'speaker-img ' + speaker.imgClass"></div>
      <div v-if="speaker.imgClass" class="circle"></div>
      <div class="cards__speaker">
        <p class="cards__name">{{ speaker.name }}</p>
        <p class="cards__spktitle" v-html="speaker.title"></p>
        <p class="cards__spktitle" v-html="speaker.position"></p>
      </div>
      <div class="cards__description">
        <p class="time">{{ speaker.time }}</p>
        <p class="location">{{ speaker.location }}</p>
      </div>
    </div>
  </div>
  <div class="cards__container">
    <div class="cards__single green">
      <p class="cards__title">
        Happy Hour
        <br>
        @ Tacos & Tequila (Condado Vanderbilt)
      </p>
      <div class="cards__description">
        <p>5:00 PM - 7:00 PM</p>
      </div>
    </div>
    <div class="cards__single green">
      <p class="cards__title">
        The Money Ball by The Money Channel<br>@ <a href="https://maps.app.goo.gl/B1doLjkM6DQDebJC9" target="_blank">Fifty Eight @ La Concha Resort</a> - 1077 Ashford Ave, San Juan, PR 00907
      </p>
      <div class="cards__description">
        <p>9:00 PM - 1:00 AM</p>
      </div>
    </div>
  </div>
  <div :class="{ 'modal-show': isModalOpen }" v-if="isModalOpen" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <div v-if="errorMessage">
        <h1 class="title">Error</h1>
        <p class="description">{{ errorMessage }}</p>
      </div>
      <div v-else>
        <h1 class="speaker">{{ activeCardInfo?.speaker || activeCompanyInfo?.company }}</h1>
        <h2 v-if="activeCardInfo" class="title">{{ activeCardInfo.title }}</h2>
        <p class="description" v-if="activeCardInfo" v-html="activeCardInfo.description"></p>
        <p class="description" v-if="activeCompanyInfo">{{ activeCompanyInfo.description }}</p>
        <a
          v-if="activeCompanyInfo?.url"
          :href="activeCompanyInfo.url"
          target="_blank"
          class="modal-link"
        >
          Visit Website
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  name: 'Day Two Afternoon',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      fauno: [
        { start: '2:00 PM', end: '2:30 PM', company: 'Tidus', hr: '14:00', duration: 29 },
        { start: '2:30 PM', end: '3:00 PM', company: 'Kredete', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '3:30 PM', company: 'Quantum Defen5e', hr: '15:00', duration: 29 }
      ],
      track1: [
        { start: '2:00 PM', end: '2:30 PM', company: 'WISA Technologies', hr: '14:00', duration: 29 },
        { start: '2:30 PM', end: '3:00 PM', company: 'Autoimmunity BioSolutions', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '3:30 PM', company: 'Beneficient', hr: '15:00', duration: 29 }
      ],
      track2: [
        { start: '2:00 PM', end: '2:30 PM', company: 'LifeVantage', hr: '14:00', duration: 29 },
        { start: '2:30 PM', end: '3:00 PM', company: 'Edible Garden', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '3:30 PM', company: 'Beam Global', hr: '15:00', duration: 29 }
      ],
      track3: [
        { start: '2:00 PM', end: '2:30 PM', company: 'WRAP', hr: '14:00', duration: 29 },
        { start: '2:30 PM', end: '3:00 PM', company: 'M2i Global', hr: '14:30', duration: 29 },
        { start: '3:00 PM', end: '3:30 PM', company: 'CIBUS', hr: '15:00', duration: 29 }
      ],
      companyData: {
        'WISA Technologies': {
          description: 'WiSA Technologies is a pioneer in wireless audio solutions, delivering high-definition, multichannel sound for home entertainment systems. Their innovative technologies, such as WiSA HT and WiSA E, enable seamless integration of wireless audio in consumer electronics, eliminating the need for speaker cables. With a focus on low-latency, high-quality audio, their products are designed to enhance immersive sound experiences for movies, music, and gaming. WiSA Technologies collaborates with leading brands to redefine the future of home entertainment audio.',
          url: 'https://www.wisatechnologies.com/'
        },
        'Defi Tech': {
          description: 'DeFi Technologies Inc. bridges traditional finance with decentralized finance (DeFi) by offering regulated and secure access to digital assets. Through its subsidiary, Valour, the company provides Exchange Traded Products (ETPs) that allow investors in Europe to gain exposure to cryptocurrencies via traditional banks and brokers. DeFi Technologies also invests in and supports innovative blockchain protocols, fostering the growth of decentralized systems.',
          url: 'https://defi.tech/'
        },
        'Beneficient': {
          description: 'Beneficient, also known as Ben®, is a financial services company that provides innovative liquidity solutions and trust administration services for alternative asset investors. Their tech-enabled platform offers secure and efficient ways to exit alternative investments, empowering clients with streamlined processes and real-time data analytics.',
          url: 'https://www.trustben.com/'
        },
        'Autoimmunity BioSolutions': {
          description: 'Autoimmunity BioSolutions (ABS) is pioneering immuno-corrective therapies for autoimmune diseases by targeting a novel pathway linked to the soluble interleukin-7 receptor (sIL7R). Their approach aims to restore normal immune function for patients with a specific genetic marker, providing safer and more effective treatment options without the risks of traditional immunosuppression.',
          url: 'https://www.abstherapeutics.com/'
        },
        'LifeVantage': {
          description: 'LifeVantage Corporation, headquartered in Lehi, Utah, is a pioneer in nutrigenomics—the study of how nutrition and naturally occurring compounds can influence gene expression to promote health. Their product line includes the Protandim® family of dietary supplements, TrueScience® skincare products, and AXIO® energy drink mixes, all designed to support wellness by combating oxidative stress and promoting healthy aging.',
          url: 'https://ka1.lifevantage.com/us-en/?gad_source=1&gclid=CjwKCAiApsm7BhBZEiwAvIu2X0qUosLZ1RdWnh96lOe2k5JLoUzYqRADR27tYtoIqtz_tp_mABBjyRoC_W4QAvD_BwE'
        },
        'Jet AI': {
          description: 'Jet.AI is a private jet charter company that leverages artificial intelligence to enhance aviation experiences for operators and customers. Their services include CharterGPT, a mobile app connecting travelers with private jet services, and advanced fleet management solutions featuring fleet positioning intelligence and carbon crediting.',
          url: 'https://jet.ai/'
        },
        'Beam Global': {
          description: 'Beam Global is a clean technology innovator that develops and manufactures sustainable infrastructure products for the electrification of transportation. Their offerings include the EV ARC™, a rapidly deployable solar-powered EV charging system, and custom battery storage solutions, all designed to provide clean mobility and energy security.',
          url: 'https://beamforall.com/'
        },
      },
      speakers: [
      {
          name: 'Panel: Stay Private vs Going public',
          title: 'Panelists:<br>- Jesse Randall, CEO & Co-Founder - Sweater<br>- William J. Hughes, Jr., Principal - PBN Law<br>- Jill M. Williamson, Principal - PBN Law<br>- Jonas Grossman, Managing Partner & President - Chardan',
          time: '3:30 PM - 4:00 PM',
          location: 'Patio del Fauno',
          class: 'noimg noheight'
        },
        {
          name: 'Panel: Current state of the micro-cap market',
          title: '<b>Moderator: Joseph Lucosky, Managing Partner - Lucosky Brookman</b>Panelist:<br>- Jason Sardo, Senior Managing Director - Maxim Group',
          time: '4:00 PM - 4:30 PM',
          location: 'Patio del Fauno',
          class: 'noimg noheight'
        },
        {
          imgClass: 'walker',
          name: 'Scott Walker',
          title: '"Early Bird gets the Worm, Tales of an early stage investor"',
          position: 'Co-Founder - DNA',
          time: '4:30 PM - 5:00 PM',
          location: 'Patio del Fauno',
        },
      ],
      cardData: {
      },
      activeCardInfo: null,
      isModalOpen: false,
      isMobile: false,
      errorMessage: null,
      eventDay: 2
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
    openModal(identifier) {
      if (this.cardData[identifier]) {
        this.activeCardInfo = this.cardData[identifier];
        this.activeCompanyInfo = null;
        this.errorMessage = null;
        this.isModalOpen = true;
      } else if (this.companyData[identifier]) {
        this.activeCompanyInfo = {
          ...this.companyData[identifier],
          company: identifier,
        };
        this.activeCardInfo = null;
        this.errorMessage = null;
        this.isModalOpen = true;
      } else {
        console.error(`Currently no info has been found for "${identifier}".`);
        this.activeCardInfo = null;
        this.activeCompanyInfo = null;
        this.errorMessage = null;
        this.isModalOpen = false;
      }
    },
    closeModal() {
      this.isModalOpen = false;
      this.activeCardInfo = null;
      this.activeCompanyInfo = null;
      this.errorMessage = null;
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
