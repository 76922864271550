<template>
  <div class="lunch">
    <h2 class="subtitle">
      Lunch
    </h2>
    <div class="cards__container">
      <div class="cards__single grayblue">
        <p class="cards__title">
          Lunch <br> @ Patio del Fauno Lobby
        </p>
        <div class="cards__description">
          <p>12:00 PM - 1:00 PM</p>
        </div>
      </div>
      <div v-for="(presenter, index) in presenters" :key="index" class="cards__single grayblue presenter"
      @click="openModal(presenter.company)">
        <div class="cards__info">
          <p class="cards__title">
            {{ presenter.company}}
          </p>
          <div class="cards__description">
            <p class="time"> {{ presenter.time }}</p>
            <p>{{ presenter.location }}</p>
          </div>
        </div>
      </div>
      <div v-for="(speaker, index) in speakers" :key="index" class="cards__single cerulean" :class="speaker.class">
        <div v-if="speaker.imgClass" :class="'speaker-img ' + speaker.imgClass"></div>
        <div v-if="speaker.imgClass" class="circle"></div>
        <div class="cards__speaker">
          <p class="cards__name">{{ speaker.name }}</p>
          <p class="cards__spktitle" v-html="speaker.title"></p>
          <p class="cards__spktitle" v-if="speaker.position" v-html="speaker.position"></p>
        </div>
        <div class="cards__description">
          <p class="time">{{ speaker.time }}</p>
          <p class="location">{{ speaker.location }}</p>
        </div>
      </div>
    </div>
  </div>
  <div :class="{'modal-show': isModalOpen}" v-if="isModalOpen" class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <h1 class="speaker">{{ activeCardInfo?.speaker }}</h1>
      <h2 class="title">{{ activeCardInfo?.title }}</h2>
      <p class="description" v-html="activeCardInfo?.description"></p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Day Two Lunch',
  data() {
    return {
      speakers: [
        {
          imgClass: 'jay',
          name: 'Jack Jay',
          title: '"The Future of Robots"',
          position: 'Founder - Open Droids',
          time: '12:30 PM - 1:00 PM',
          location: 'Patio del Fauno'
        },
        {
          name: 'Panel: Bitcoin on the Balance Sheet a public markets multiplier',
          title: '<b>Moderator: Brandon Green, Chief of Staff & Head of Events - BTC Inc.</b>Panelists:<br>- Hans Thomas, CEO - 10X Capital<br>- Christian Lopez, Head of Blockchain & Digital Infrastructure - Cohen & Company Capital Markets<br>- Josip Rupena, Founder & CEO - Milo <br>- Max Staedtler, Head of Investment Banking - Anthem',
          time: '1:00 PM - 2:00 PM',
          location: 'Patio del Fauno',
          class: 'noimg noheight'
        }
      ],
      presenters: [
        {
          company: 'Chintai',
          time: '12:00 PM - 12:30 PM',
          location: 'Patio del Fauno'
        }
      ],
      cardData: {
      },
      activeCardInfo: null,
      isModalOpen: false
    }
  },
  methods: {
    openModal(cardId) {
      console.log()
      this.activeCardInfo = this.cardData[cardId];
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.activeCardInfo = null;
    }
  },
};
</script>