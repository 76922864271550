<template>
  <section class="hotel">
    <div class="hotel__content">
      <!--<div class="hotel__banner">
        Our room block at the Condado Vanderbilt is SOLD OUT - <a href="/#book">Click here to see other options</a>
      </div>-->
      <div class="hotel__info container">
        <p class="hotel__description">
          Returning for its third highly anticipated edition, the Sequire Investor Summit takes place at the prestigious
          <b>Condado Vanderbilt Hotel in San Juan, Puerto Rico.</b> Hosted by SRAX, this premier event brings together
          visionary speakers, 75+ presenting companies, and over 500 investors, funds, family offices, and
          analysts for an unforgettable experience.
        </p>
        <p class="hotel__description">
          Join us for a remarkable gathering filled with insightful discussions, innovative ideas, and unparalleled networking
          opportunities. Be part of this exclusive event where connections flourish, innovation thrives, and the future is shaped.
        </p>
      </div>
      <div class="hotel__cards">
        <div class="hotel__card--content container">
            <div class="card">
                <img src="../assets/location/Icon1.svg" alt="Companies Icon">
                <h3 class="card__title">
                    <b>Company Presentations</b><br>
                    Public, Private and Crypto
                </h3>
                <p class="card__description">Join engaging industry panels led by visionary leaders driving innovation across the sector.</p>
            </div>
            <div class="card">
                <img src="../assets/location/Icon2.svg" alt="Attendees Icon">
                <h3 class="card__title">
                    <b>500+</b><br>
                    Attendees & Investors
                </h3>
                <p class="card__description">Family Funds, Equity Groups, Institutional and Retail Investors and more.</p>
            </div>
            <div class="card">
                <img src="../assets/location/Icon3.svg" alt="Investors Icon">
                <h3 class="card__title">
                    <b>Hundreds</b><br>
                    of Networking Opportunities
                </h3>
                <p class="card__description">Connect with the world's top investment leaders in a stunning tropical paradise, complete with unforgettable networking opportunities and vibrant nightly entertainment.</p>
            </div>
        </div>
        <generalformcta />
      </div>
      <div class="hotel__carousel">
        <Carousel :items-to-show="computedItemsToShow" :wrap-around="true">
          <Slide v-for="(image, index) in images" :key="index">
            <div class="hotel__carousel--item">
              <img :src="image" :alt="'Image ' + index">
            </div>
          </Slide>
          <template #addons>
            <Navigation class="carousel-nav"/>
          </template>
        </Carousel>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/hotel.scss';
</style>

<style lang="scss">
@import '@/styles/import/variables.scss';
@import '@/styles/import/mixins.scss';

  .hotel {
    .carousel__prev,
    .carousel__next {
      background-color: #fff;
      border-radius: 50%;
      &:hover {
        color: #000;
      }
    }
    .carousel-nav {
      z-index: 2;
      @include from ($breakpoint_lg) {
        margin: 0 25px;
      }
    }
  }
</style>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css';
import generalformcta from '@/components/generalformcta.vue';

export default defineComponent({
  name: 'Hotel',
  components: {
    Carousel,
    Slide,
    Navigation,
    generalformcta
  },
  computed: {
    computedItemsToShow() {
      const width = window.innerWidth;
      if (width < 500) {
        return 1.5;
      } else if (width >= 500 && width < 900) {
        return 2;
      } else if (width >= 900 && width < 1300) {
        return 2.5;
      } else {
        return 3.2;
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.$forceUpdate();
    }
  },
  data() {
    return {
      images: [
        require('@/assets/event/1.png'),
        require('@/assets/event/4.png'),
        require('@/assets/event/2.png'),
        require('@/assets/event/6.png'),
        require('@/assets/hotel/hotel-3.jpeg'),
        require('@/assets/hotel/hotel-5.jpeg'),
        require('@/assets/hotel/hotel-6.jpeg'),
        require('@/assets/hotel/hotel-7.jpeg'),
        require('@/assets/hotel/hotel-7a.jpeg'),
        require('@/assets/hotel/hotel-8.jpeg'),
        require('@/assets/hotel/hotel-8a.jpeg'),
        require('@/assets/hotel/hotel-8b.jpeg'),
        require('@/assets/hotel/hotel-9.jpeg'),
        require('@/assets/hotel/hotel-10.jpeg'),
        require('@/assets/hotel/hotel-10a.jpeg'),
        require('@/assets/hotel/hotel-11.jpeg'),
        require('@/assets/hotel/hotel-11a.jpeg'),
        require('@/assets/hotel/hotel-12.jpeg'),
        require('@/assets/hotel/hotel-13.jpeg'),
        require('@/assets/hotel/hotel-13a.jpeg'),
        require('@/assets/hotel/hotel-14.jpeg'),
        require('@/assets/hotel/hotel-15.jpeg'),
        require('@/assets/hotel/hotel-16.jpeg'),
        require('@/assets/hotel/hotel-17.jpeg'),
        require('@/assets/hotel/hotel-18.jpeg'),
        require('@/assets/hotel/hotel-19.jpeg'),
        require('@/assets/hotel/hotel-20.jpeg'),
        require('@/assets/hotel/hotel-20a.jpeg'),
        require('@/assets/hotel/hotel-21.jpeg'),
        require('@/assets/hotel/hotel-22.jpeg'),
        require('@/assets/hotel/hotel-23.jpeg'),
        require('@/assets/hotel/hotel-24.jpeg'),
        require('@/assets/hotel/hotel-25.jpeg'),
        require('@/assets/hotel/hotel-26.jpeg'),
        require('@/assets/hotel/hotel-27.jpeg'),
        require('@/assets/hotel/hotel-27a.jpeg'),
        require('@/assets/hotel/hotel-28.jpeg'),
        require('@/assets/hotel/hotel-29.jpeg'),
        require('@/assets/hotel/hotel-30.jpeg'),
        require('@/assets/hotel/hotel-31.jpeg'),
        require('@/assets/hotel/hotel-2a.jpg'),
        require('@/assets/hotel/hotel-1a.jpg'),
      ],
    };
  },
});

</script>