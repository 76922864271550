<template>
  <section class="hero">
    <video class="hero__background-video" autoplay muted loop>
      <source src="@/assets/hero/bg.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div class="hero__overlay"></div>
    <div class="hero__content container">
      <div class="hero__content--info">
        <h1 class="hero__title">
          Sequire Investor Summit
        </h1>
        <h2 class="hero__date">
          Thank You for Making 2025 a Wonderful Experience!
        </h2>
        <h3 class="hero__location">
          We Hope to See You Again in 2026
        </h3>
        <a href="https://forms.monday.com/forms/5ab96b8f289480d9d3479556bcc057e0?r=use1" target="_blank">
          <button class="hero__btn">
            Register for 2026
          </button>
        </a>
      </div>
    </div>
  </section>
</template>

<script>

  export default {
    name: 'Hero',
    data () {
      return {
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
  @import "../styles/components/hero.scss";
  </style>